import { BLOCKED_MEMBER_ID } from '../../../constants/common';
import PublicMemberBuilder from '../../../services/public-member-builder';
import { getSetViewedMemberAction } from '../../actions';
import { IFrameEvent, PublicMember, ThunkWithArgs } from '../../../types';
import { openModalWithCallback } from '../../../services/modal';
import { RoleId } from '@wix/members-domain-ts';

const getBlockedMember = (): PublicMember => {
  return new PublicMemberBuilder().withUid(BLOCKED_MEMBER_ID).build();
};

export const memberBlockMember: ThunkWithArgs<RoleId> =
  (roleId) => (dispatch, getState, extra) => {
    const { compId, wixCodeApi, platformAPIs, experiments, dataSyncService } =
      extra;
    const state = getState();
    const { viewed } = state.users;
    const { uid } = viewed;

    const onConfirm = async () => {
      const { blockMemberService } = extra;
      await blockMemberService.blockMember(uid);
      dataSyncService.addIFrameEvent(IFrameEvent.SetMemberAsBlocked);
      dispatch(getSetViewedMemberAction(getBlockedMember()));
    };

    openModalWithCallback({
      compId,
      modalType: roleId,
      payload: { memberName: viewed.name },
      platformAPIs,
      wixCodeApi,
      experiments,
      onConfirm,
    });
  };
